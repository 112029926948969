import * as React from "react";
import {useRef, useEffect} from 'react';
import Tilt from 'react-parallax-tilt';

import Layout from '../components/layout'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { parallaxeffectimg, pulse, ring} from '../components/layout.module.css'



gsap.registerPlugin(ScrollTrigger);





function IndexPage(){
    const catlogo = useRef(null);

    const spanCats = useRef(null);
    const movable0 = useRef(null);
    const movable1 = useRef(null);
    const movable2 = useRef(null);
    const movable3 = useRef(null);
    const movable4 = useRef(null);
    const movable5 = useRef(null);
    const movable6 = useRef(null);

    const scrolling = useRef(null);

    const button1 = useRef(null);

    const totarget = useRef(null);

    const div1 = useRef(null);

    const lead1 = useRef(null);
    const lead2 = useRef(null);
    const lead3 = useRef(null);
    const lead4 = useRef(null);

    const lead5 = useRef(null);
    const lead6 = useRef(null);
    const lead7 = useRef(null);
    const lead8 = useRef(null);
    const lead9 = useRef(null);
    const lead10 = useRef(null);
    const lead11 = useRef(null);
    const lead12 = useRef(null);
    const lead13 = useRef(null);
    const lead14 = useRef(null);
    const lead15 = useRef(null);
    const lead16 = useRef(null);

    //entry animations

    useEffect(() => {
        
        const sl =  scrolling.current;
        const b1 =  button1.current;

        
        const l1 =  lead1.current;
        const l2 =  lead2.current;
        const l3 =  lead3.current;
        

        const tl = gsap.timeline()
        let tweet = tl.from(l1,{opacity:0,y:50, duration: 2, ease: 'none'},0.1)
                    .from(l2,{opacity:0, y:50, duration: 1.5, ease: 'none'}, 0.6)
                    .from(l3,{opacity:0, y:-100, duration: 1, ease: 'none'}, 2)
                    .from(sl,{opacity:0, y:-100, duration: 1, ease: 'none'}, 2)
                    .to(b1, {
                        scale:1.06,
                        repeat:-1,
                        yoyo: true,
                      });
                    
        tweet.play();

        

        

      }, [])

    //scrolling the word cat

    
      
    

      
    return (
        <Layout pageTitle="Home Page" > 
        
            <div ref={movable1} className="relative flex flex-col w-full">
           
                
                
                <div className="absolute flex flex-row justify-end items-end w-full text-white h-screen leading-tight">
                        <div className="flex flex-col w-full text-white pb-28 md:pb-20 items-center justify-center">
                            <h1 className="font-moderno text-[6vw] md:text-[4vw] text-center font-bold drop-shadow-3xl" ref={lead1}>Welcome to the Happy Cats Space!</h1>
                            <h1 className="font-moderno text-[6vw] md:text-[4vw] text-center font-extralight" ref={lead2}>We're currently gearing up for our big launch.</h1>
                        </div>   
                </div>    
            </div> 
            
            <div className="flex flex-col min-w-full max-w-full bg-gradient-to-t from-purple3 to-pink4" >
                <section ref={movable0} className="flex flex-col justify-center items-center w-full h-screen">
                
                    <div ref={catlogo} className="flex flex-row justify-center items-center w-full h-3/4 mb-20 md:mb-48 ">
                    
                        <Tilt className={parallaxeffectimg}
                            tiltMaxAngleX={30}
                            tiltMaxAngleY={30}
                            perspective={1100}
                            transitionSpeed={50}
                            scale={1}
                            gyroscope={false}>
                    
                            
                        </Tilt>
                        
                    </div>    
                    
                </section> 
                <div className="absolute flex flex-row items-center justify-between w-full text-white h-[15vw] md:h-[7vw]">
                
                    <div className="font-moderno text-[5vw] md:text-[2vw] px-3 md:px-10 py-2 md:py-10 font-bold" ref={lead3}></div>
                    
                    <div className="cursor-pointer flex flex-row items-center py-2 md:py-10 px-3 md:px-10" ref={scrolling} onClick={() => window.location = 'mailto:meow@happycats.space'}>
                    
                        <div className="font-moderno text-[3vw] md:text-[2vw] mx-2 md:mx-6 font-extralight text-white">can't wait?</div>  
                           
                            <div className="relative h-[10vw] md:h-[4vw] w-[10vw] md:w-[4vw]">
                                <div className={pulse}>
                                    <div className={ring}></div>
                                    <div className={ring}></div>
                                    <div className={ring}></div>
                                    <div className={ring}></div>
                                    <div className="absolute w-full h-full flex flex-row items-center justify-center"><h1 className="text-[2.5vh] md:text-[3.5vh] text-transparent bg-clip-text bg-gradient-to-r from-purple3 to-pink4">&gt;</h1></div>
                                
                                </div>    
                                
                            </div>
                    </div>
                </div> 
            </div>
     </Layout>
    )
}  
export default IndexPage